import {
  CreateChapter,
  UploadChapterPhoto,
  AddChapterDomain,
  AssignChapterMember,
  UnassignChapterMember,
  PromoteUser,
  DemoteUser,
  DeactivateChapter,
  ReactivateChapter
} from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("ChapterApi", { level: DEBUG });

export async function createChapter(descriptionJson, lat, lng, title) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.ALREADY_DELETED, "This chapter was previously created and deleted."],
    [BE.ALREADY_EXISTS, "A chapter with this name already exists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.NOT_EXIST, "The GPS location was not correct."],
    [BE.INSERT_FAILED, "Error creating this chapter."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CreateChapter,
        variables: {
          descriptionJson,
          lat,
          lng,
          title
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uploadChapterPhoto(chapterId, photoBase64Image) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Please choose a file to upload."],
    [BE.INVALID_FORMAT, "The file you selected was not in a valid format."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Your photo failed to upload."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UploadChapterPhoto,
        variables: {
          chapterId,
          photoBase64Image
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function addChapterDomain(chapterId, domain) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error creating this chapter page."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AddChapterDomain,
        variables: {
          chapterId,
          domain
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function assignChapterMember(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PERMISSION, "You do not have permission to assign chapter member on behalf of User."],
    [BE.NOT_EXIST, "Chapter or user not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error assigning chapter member."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AssignChapterMember,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unassignChapterMember(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PERMISSION, "You don't have permission to unassign this chapter member. You can only remove yourself if you are not an admin."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error unassigning chapter member."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnassignChapterMember,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function promoteUser(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "User or Chapter does not exist."],
    [BE.NOT_BELONG, "Can not promote someone who is not a member of the Chapter."],
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error promoting user."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: PromoteUser,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function demoteUser(userId, chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "User or Chapter does not exist."],
    [BE.NOT_BELONG, "Can not demote someone who is not a member of the Chapter."],
    [BE.PERMISSION, "Must first remove this user as an organizer for all Series within the Chapter."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error demoting user."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DemoteUser,
        variables: {
          userId,
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function deactivateChapter(chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."],
    [BE.NOT_SUPPORTED, "Must deactivate all Series within Chapter before Chapter can be deactivated."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error deactivating chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DeactivateChapter,
        variables: {
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reactivateChapter(chapterId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Chapter does not exist."]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error reactivating chapter."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReactivateChapter,
        variables: {
          chapterId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
